import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageHead } from "../ui/page/page";
import API, { setRequest } from "../API";
import InputText from "../ui/input/input";
import Button from "../ui/button/button";
import Form, { FormActions, FormBody, FormHeader } from "../ui/form/form";
import Tile from "../components/tile/tile";
import Table, { Table_LoadMask } from "../ui/table/table";
import Paginator from "../components/paginator/paginator";
import Status, { IStatus } from "../components/status/status";
import OrdersStore, { IOrder } from "../store/ordersStore";
import { observer } from "mobx-react-lite";

interface Props {

}

const Orders_Page: FC<Props> = observer((props: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        OrdersStore.setPaginationLimit(20)
    }, [])
    useEffect(() => {
        // setData([])
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: `/orders/?${OrdersStore.getPaginationUrl()}`,
            success: (payload) => {
                setLoaded(true)
                OrdersStore.setItems(payload.data)
                OrdersStore.setPagination(payload.pagination)
            },
            error: (err) => {
                OrdersStore.setItems([])
                setLoaded(true)
            }
        })
    }, [OrdersStore.pagination.page])

    return (
        <Page>
            <PageHead title={"Заказы"} />
            <Tile
                loaded={loaded}
                loadMask={<Table_LoadMask />}
            >
                <Table thead={[
                    {
                        width: '8%',
                        title: '#'
                    },
                    {
                        width: '8%',
                        title: 'ID площадки'
                    },
                    {
                        width: '12%',
                        title: 'Дата'
                    },
                    {
                        width: '18%',
                        title: 'ФИ'
                    },
                    {
                        width: '10%',
                        title: 'E-mail'
                    },
                    {
                        width: '10%',
                        title: 'Телефон'
                    },
                    {
                        width: '10%',
                        title: 'Статус'
                    },
                    {
                        width: '8%',
                        title: 'Сумма'
                    },
                    {
                        width: '8%',
                        title: 'Промокод'
                    },
                    {
                        width: '8%',
                        title: 'Тип'
                    },
                    {
                        width: '40px',
                        title: ''
                    },
                ]}
                    tbody={OrdersStore.items.map(order => [
                        order.id,
                        order.list[0].product_id,
                        order.date,
                        `${order.customer.lastName} ${order.customer.name}`,
                        order.customer.email,
                        order.customer.phone,
                        <Status {...order.status} />,
                        order.total,
                        order.promocode || '-',
                        order.payment,
                        <div className="actions">
                            <Button type={'primary'} icon="share"></Button>
                        </div>
                    ])}
                />
            </Tile>
            <Paginator {...OrdersStore.pagination} goToPage={p => OrdersStore.goToPage(p)} />
        </Page >
    )
})

export default Orders_Page