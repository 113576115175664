import { makeAutoObservable } from "mobx";
import ListStore, { IPagination } from "./_listStore";
import { IStatus } from "../components/status/status";



export interface ITicketsItem {
    available: number,
    booked: number,
    correction: number,
    count: number,
    id: number,
    list: any,
    date_start: number,
    date_end: number,
    place_id: number,
    place_title: string,
    price: number,
    price_old: number,
    sold: number,
    status: IStatus,
    is_deleted: boolean,
}
export interface ICustomer {
    id: number,
    ticket_id: number,
    count: number,
    email: string,
    name: string,
    last_name: string,
    promocode: string
    phone: string,
}
export interface ITicketsItemDetail {
    count: number,
    date_end: number,
    date_end_formatted: string,
    date_start: number,
    date_start_formatted: string,
    id: number,
    customers: ICustomer[],
    place_id: number,
    place_title: string,
    price: number,
    price_old: number,
    sold: number,
    booked: number,
    correction: number,
    available: number,
    properties: {
        option: number | null,
        season: number | null,
        sport: number | null,
        status: number | null,
    }
    status: IStatus,
    timeZoneOffset: number;
}
export interface ITicketsItemDetailError {
    count: string | null,
    date_end: string | null,
    date_start: string | null,
    id: string | null,
    list: string | null,
    place: string | null,
    price: string | null,
    price_old: string | null,
    option: string | null,
    season: string | null,
    sport: string | null,
    status: string | null,
    // sold: number,
    // booked: number,
    // correction: number,
    // available: number,

}
export interface ITicketsItemGroup {
    date: string,
    timestamp: number,
    list: ITicketsItem[]
}

interface ITicketsStore {
    items: ITicketsItemGroup[],
    editItem: ITicketsItemDetail
}

export const TTicketsItemDetail = {
    // count: null,
    // date_end: null,
    // date_end_formatted: null,
    // date_start: null,
    // date_start_formatted: null,
    // id: null,
    // list: null,
    // place_id: null,
    // place_title: null,
    // price: null,
    // price_old: null,
    // sold: null,
    // booked: null,
    // correction: null,
    // available: null,
    properties: {
        option: null,
        season: null,
        sport: null,
        status: null,
    }
}

class _TicketsStore implements ITicketsStore {
    items = [] as ITicketsItemGroup[]
    editItem = {} as ITicketsItemDetail
    editItemError = {} as ITicketsItemDetailError
    constructor() {
        makeAutoObservable(this)
    }
    setItems = (items: ITicketsItemGroup[]) => {
        this.items = items;
    }
    setEditItemFull = (item: ITicketsItemDetail) => {
        this.editItem = item
    }
    setEditItemError = (item?: ITicketsItemDetailError) => {
        if (item) {
            this.editItemError = item
        } else {
            this.editItemError = {} as ITicketsItemDetailError
        }
    }
    setEditItemTemplate = () => {
        this.editItem = TTicketsItemDetail as ITicketsItemDetail
    }
    setEditItem = <K extends keyof ITicketsItemDetail>(
        field: K,
        value: ITicketsItemDetail[K]) => {
        this.editItem[field] = value
    }
    setEditItemProperties = <K extends keyof ITicketsItemDetail['properties']>(
        field: K,
        value: ITicketsItemDetail['properties'][K]) => {
        this.editItem.properties[field] = value
    }
    setEditItemCustomer = <K extends keyof ICustomer>(
        field: K,
        cId: number | null,
        value: ICustomer[K],
    ) => {
        if (cId === null) return;
        
        let customer = this.getEditItemCustomerById(cId)
        if (customer) {
            customer[field] = value
            console.log('TEST', customer, field, customer.id, cId, value)
        }
    }
    getEditItemCustomerById = (cId: number | null) => {
        if (cId === null) return;
        return this.editItem.customers.filter(c => c.id === cId)[0]
    }
}

const TicketsStore = new _TicketsStore()

export default TicketsStore;