import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import ListStore, { IPagination } from "./_listStore";
import { IStatus } from "../components/status/status";



export interface IOrder {
    count: number,
    customer: {
        id: number,
        name: string,
        lastName: string
        email: string,
        phone: string
    },
    date: string,
    discount: string,
    id: number,
    list: any,
    promocode: string,
    status: IStatus,
    payment: string
    total: number
}

interface IOrdersStore {
    items: IOrder[],
}

class _OrdersStore extends ListStore implements IOrdersStore {
    items = [] as IOrder[]
    constructor() {
        super();
        this.name = '__orders__'
        makeObservable(this, {
            items: observable,
            setItems: action,
        })
    }
    setItems = (items: IOrder[]) => {
        this.items = items;
    }
}

const OrdersStore = new _OrdersStore()

export default OrdersStore;