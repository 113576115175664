import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
// import InputText, } from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { useNavigate } from "react-router-dom";
import AppStore, { IPropertyItem } from "../../store/store";
import TicketsStore from "../../store/ticketsStore";
import { observer } from "mobx-react-lite";
import Input from "../../ui/input/input";
import { IPlace } from "../../store/placesStore";
import { getFormattedDate } from "../../helper";
import NotifStore from "../../store/_notifStore";
import PromoStore from "../../store/promoStore";
import { IReportCustomer } from "../../store/reportsStore";

interface Props {

}

let defaultProperty = {
    id: 0,
    title: 'Не выбрано'
}

const Promo_Add_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();

    const [inited, setInited] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(true)
    const [users, setUsers] = useState<IPropertyItem[]>([defaultProperty])
    const [places, setPlaces] = useState<IPropertyItem[]>([defaultProperty])
    const [usersLoading, setUsersLoading] = useState<boolean>(false)


    useEffect(() => {
        PromoStore.setEditItemTemplate()
        // PromoStore.setEditItemError()
        setInited(true)
        getPlaces()
    }, [])

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: "POST",
            url: '/promocodes/add/',
            data: PromoStore.editItem,
            success: (payload) => {
                console.log(payload)
                setLoaded(true)
                PromoStore.setEditItemError()
                NotifStore.setSuccess('Промокод успешно добавлен', 'Промокод новый')
                navigate('/promo/')
            },
            error: (payload) => {
                if (payload.errors) {
                    PromoStore.setEditItemError(payload.errors)
                }
                setLoaded(true)
                NotifStore.setWarning('Ошибка добавления промокода', 'Промокод новый')
            }
        })
    }


    const getUsers = (str: string) => {
        if (!str) return
        setUsersLoading(true)
        setRequest({
            type: 'GET',
            url: `/customers/search/?query=${str}`,
            success: payload => {
                console.log(payload)
                let customerArr = payload.data.map((customer: IReportCustomer) => {
                    return {
                        id: customer.id,
                        title: `${customer.surname} ${customer.name} ${customer.phone}`,
                        customer: customer
                    }
                })
                customerArr.unshift(defaultProperty)
                setUsers(customerArr)
                setUsersLoading(false)
                // setLoading(false)
            },
            error: (err) => {
                setUsersLoading(false)
                // setLoading(false)
            }
        })
    }

    const getPlaces = () => {
        setRequest({
            type: 'GET',
            url: `/places/`,
            success: payload => {
                let arr = payload.data.map((place: IPlace) => {
                    return {
                        id: place.id,
                        title: place.title
                    }
                })
                arr.unshift(defaultProperty)
                setPlaces(arr)
                // setLoading(false)
            },
            error: (err) => {
                // setLoading(false)
            }
        })
    }


    return (
        <Page>
            <PageHead
                title={"Добавить промокод"}
            />
            <PageBody>
                <Tile
                    className="ticketsAdd"
                    loaded={inited}
                    loader={true}
                >
                    <Form asModule={false}>
                        <FormBody>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label="Название"
                                        value={PromoStore.editItem.title}
                                        onChange={val => PromoStore.setEditItem('title', val)}
                                        error={PromoStore.editItemError.title}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label="Сумма/процент"
                                        value={PromoStore.editItem.amount}
                                        onChange={val => PromoStore.setEditItem('amount', val)}
                                        error={PromoStore.editItemError.amount}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Статус"}
                                        value={PromoStore.editItem.status}
                                        options={AppStore.getProperty('status').list}
                                        onChange={(id) => PromoStore.setEditItem('status', id)}
                                        error={PromoStore.editItemError.status}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Тип"}
                                        value={PromoStore.editItem.type}
                                        options={AppStore.getProperty('promocodeTypes').list}
                                        onChange={(id) => PromoStore.setEditItem('type', id)}
                                        error={PromoStore.editItemError.type}
                                    // error={PromoStore.editItemError.sport}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        // type="multiselect"
                                        label={"Выбрать игрока"}
                                        value={PromoStore.editItem.userId}
                                        options={users}
                                        loading={usersLoading}
                                        onInput={val => {
                                            getUsers(val)
                                        }}
                                        onChange={(id) => {

                                            PromoStore.setEditItem('userId', id)
                                        }}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        // type="multiselect"
                                        label={"Выбрать площадку"}
                                        value={PromoStore.editItem.eventId}
                                        options={places}
                                        onChange={(id) => {
                                            PromoStore.setEditItem('eventId', id)
                                        }}
                                    />
                                </FormCol>
                            </FormRow>
                        </FormBody>
                        <FormActions>
                            <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Добавить</Button>
                            <Button type="secondary" onClick={() => navigate('/tickets')}>Отменить</Button>
                        </FormActions>
                    </Form>
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Promo_Add_Page