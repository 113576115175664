import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
// import InputText, } from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { useNavigate } from "react-router-dom";
import AppStore from "../../store/store";
import TicketsStore from "../../store/ticketsStore";
import { observer } from "mobx-react-lite";
import Input from "../../ui/input/input";
import { IPlace } from "../../store/placesStore";
import { getFormattedDate } from "../../helper";
import NotifStore from "../../store/_notifStore";

interface Props {

}

const Tickets_Add_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();

    const [inited, setInited] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(true)
    const [places, setPlaces] = useState<IPlace[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<IPlace>({} as IPlace);

    useEffect(() => {
        TicketsStore.setEditItemTemplate()
        TicketsStore.setEditItemError()
        setRequest({
            type: 'GET',
            url: '/places/',
            success: (payload) => {
                setPlaces(payload.data)
                setInited(true)
            },
            error: (err) => {
                setPlaces([])
            }
        })
    }, [])

    // console.log(TicketsStore.editItem.date_end,)

    const selectPlace = (id: number) => {
        let place: IPlace = places.filter(place => place.id === id)[0]
        if (place) {
            TicketsStore.setEditItem('count', place.ticketCount)
            TicketsStore.setEditItem('place_id', place.id)
            TicketsStore.setEditItem('price_old', place.ticketPrice)
            setSelectedPlace(place)
        }
    }

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: "POST",
            url: '/tickets/',
            data: TicketsStore.editItem,
            success: (payload) => {
                console.log(payload)
                setLoaded(true)
                TicketsStore.setEditItemError()
                NotifStore.setSuccess('Билет успешно добавлен', 'Билет новый')
                navigate('/tickets/')
            },
            error: (payload) => {
                if (payload.errors) {
                    TicketsStore.setEditItemError(payload.errors)
                }
                setLoaded(true)
                NotifStore.setWarning('Ошибка добавления билета', 'Билет новый')
            }
        })
    }
    return (
        <Page>
            <PageHead
                title={"Добавить игру"}
            />
            <PageBody>
                <Tile
                    className="ticketsAdd"
                    loaded={inited}
                    loader={true}
                >
                    <Form asModule={false}>
                        <FormBody>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Вид спорта"}
                                        value={TicketsStore.editItem.properties?.sport}
                                        options={AppStore.getProperty('sport').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('sport', id)}
                                        error={TicketsStore.editItemError.sport}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Сезон"}
                                        value={TicketsStore.editItem.properties?.season}
                                        options={AppStore.getProperty('season').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('season', id)}
                                        error={TicketsStore.editItemError.season}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Площадка"}
                                        value={selectedPlace.id}
                                        options={places}
                                        onChange={selectPlace}
                                        error={TicketsStore.editItemError.place}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Всего мест"}
                                        value={TicketsStore.editItem.count}
                                        onChange={value => TicketsStore.setEditItem('count', value)}
                                        error={TicketsStore.editItemError.count}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Старая цена"}
                                        value={TicketsStore.editItem.price_old}
                                        onChange={value => TicketsStore.setEditItem('price_old', value)}
                                        error={TicketsStore.editItemError.price_old}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Цена"}
                                        value={TicketsStore.editItem.price}
                                        onChange={value => TicketsStore.setEditItem('price', value)}
                                        error={TicketsStore.editItemError.price}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="datetime"
                                        format="dd.MM.yyyy hh:mm"
                                        label={"Дата начала"}
                                        value={TicketsStore.editItem.date_start}
                                        hourList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]}
                                        minuteList={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                                        onChange={value => {
                                            TicketsStore.setEditItem('date_start', value)
                                        }}
                                        error={TicketsStore.editItemError.date_start}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="datetime"
                                        format="dd.MM.yyyy hh:mm"
                                        label={"Дата окончания"}
                                        value={TicketsStore.editItem.date_end}
                                        hourList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]}
                                        minuteList={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                                        onChange={value => {
                                            TicketsStore.setEditItem('date_end', value)
                                        }}
                                        error={TicketsStore.editItemError.date_end}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Опции"}
                                        value={TicketsStore.editItem.properties?.option}
                                        options={AppStore.getProperty('option').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('option', id)}
                                        error={TicketsStore.editItemError.option}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Статус"}
                                        value={TicketsStore.editItem.properties?.status}
                                        options={AppStore.getProperty('status').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('status', id)}
                                        error={TicketsStore.editItemError.status}
                                    />
                                </FormCol>
                            </FormRow>
                        </FormBody>
                        <FormActions>
                            <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Добавить</Button>
                            <Button type="secondary" onClick={() => navigate('/tickets')}>Отменить</Button>
                        </FormActions>
                    </Form>
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Tickets_Add_Page