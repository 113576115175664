import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import ListStore, { IPagination } from "./_listStore";
import { IStatus } from "../components/status/status";
import { IGalleryImage } from "../components/gallery/gallery";



export interface IPromo {
    amount: number | null,
    eventId: number | null,
    id: number | null,
    status: IStatus,
    title: string,
    type: number | null,
    used: boolean,
    userId: number | null,
    currency: string,
}

export interface IPromoError {
    amount: string,
    eventId: string,
    id: string,
    status: string,
    title: string,
    type: string,
    used: string,
    userId: string,
    currency: string,
}

export const TPromo: IPromo = {
    amount: null,
    eventId: null,
    id: null,
    status: {} as IStatus,
    title: '',
    type: null,
    used: false,
    userId: null,
    currency: '',
}



interface IPromoStore {
    items: IPromo[],
    editItem: IPromo,
    editItemError: IPromoError
}

class _PromoStore extends ListStore implements IPromoStore {
    items = [] as IPromo[]
    editItem = {
        status: {}
    } as IPromo
    editItemError = {} as IPromoError
    constructor() {
        super();
        this.name = '__Promo__'
        makeObservable(this, {
            items: observable,
            editItem: observable,
            setItems: action,
        })
    }
    setItems = (items: IPromo[]) => {
        this.items = items;
    }
    setEditItemFull = (item: IPromo) => {
        this.editItem = item
    }
    setEditItemTemplate = () => {
        this.editItem = TPromo
    }
    setEditItem = <K extends keyof IPromo>(
        field: K,
        value: IPromo[K]) => {
        this.editItem[field] = value
        this.editItemError[field] = ''
    }
    setEditItemError = (item?: IPromoError) => {
        if (item) {
            this.editItemError = item
        } else {
            this.editItemError = {} as IPromoError
        }
    }
}

const PromoStore = new _PromoStore()

export default PromoStore;