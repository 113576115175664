import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageHead } from "../ui/page/page";
import Table, { Table_LoadMask } from "../ui/table/table";
import Tile from "../components/tile/tile";
import { setRequest } from "../API";
import PlayersStore from "../store/_playersStore";
import Paginator from "../components/paginator/paginator";
import Form, { FormBody, FormCol, FormRow } from "../ui/form/form";
import Input from "../ui/input/input";
import Button from "../ui/button/button";

interface Props {

}


const Players_Page: FC<Props> = observer((props: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false)
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        PlayersStore.setPaginationLimit(20)
    }, [])

    useEffect(() => {
        load()
    }, [PlayersStore.pagination.page])

    const load = () => {
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: `/customers/list/?${PlayersStore.getPaginationUrl()}`,
            success: (payload) => {
                setLoaded(true)
                PlayersStore.setItems(payload.data)
                PlayersStore.setPagination(payload.pagination)
            },
            error: (err) => {
                PlayersStore.setItems([])
                setLoaded(true)
            }
        })
    }

    const searchUsers = () => {
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: `/customers/search/?query=${value}`,
            success: (payload) => {
                setLoaded(true);
                PlayersStore.setItems(payload.data);
            },
            error: (err) => {
                PlayersStore.setItems([]);
                setLoaded(true);
            }
        })
    }

    return (
        <Page>
            <PageHead
                title={"База игроков"}
            />
            <Form asModule={false} className="__searchForm">
                <FormBody>
                    <FormRow>
                        <FormCol asRow>
                            <Input
                                type="text"
                                label="Поиск по имени пользователя"
                                value={value}
                                onChange={v => setValue(v)}
                            />
                            <Button
                                type="primary"
                                onClick={searchUsers}
                            >
                                Поиск
                            </Button>
                        </FormCol>
                    </FormRow>
                </FormBody>
            </Form>
            <Tile
                loaded={loaded}
                loadMask={<Table_LoadMask />}
            >
                <Table thead={[
                    {
                        width: '8%',
                        title: '#'
                    },
                    {
                        width: '24%',
                        title: 'Имя'
                    },
                    {
                        width: '24%',
                        title: 'Фамилия'
                    },
                    {
                        width: '24%',
                        title: 'E-mail'
                    },
                    {
                        width: '24%',
                        title: 'Телефон'
                    },
                ]}
                    tbody={PlayersStore.items.map(player => [
                        player.id,
                        player.name || "-",
                        player.lastName || '-',
                        player.email || '-',
                        player.phone || '-',
                    ])}
                />
            </Tile>
            <Paginator {...PlayersStore.pagination} goToPage={p => PlayersStore.goToPage(p)} />
        </Page>
    )
})

export default Players_Page