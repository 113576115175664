import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import ListStore, { IPagination } from "./_listStore";
import { IStatus } from "../components/status/status";
import { ICustomer } from "./ticketsStore";
import AppStore, { IPropertyItem } from "./store";



export interface IReport {
    id: number,
    date: string,
    timestamp: number,
    place_title: string,
    properties: {
        metro: number,
        format: number,
        area: number,
        season: number,
    }
    status: IStatus,
}

export interface IReportCustomer {
    email: string,
    id: number,
    image: string,
    name: string,
    surname: string,
    phone: string,
}

interface IReportDetail {
    date: string,
    id: number,
    place_id: number,
    place_title: string,
    status: IStatus,
    timestamp: number,
    winner: number,
    properties: {
        metro: number,
        format: number,
        area: number,
        season: number,
    },
    result: {
        sort: number,
        teams: {
            id: number,
            score: number,
        }[]
    }[],
    stats: {
        best: number[],
        customer: IReportCustomer,
        goal: number | null,
        // missed: number | null,
        pass: number | null,
        role: number | null,
        team: number | null,
    }[]
    teams: (undefined | number)[],
}

interface IReportsStore {
    items: IReport[],
}

// interface IReportsStoreTeams {

// }

const TStat: IReportDetail['stats'][0] = {
    best: [],
    customer: {} as IReportCustomer,
    goal: 0,
    // missed: null,
    pass: 0,
    role: 145,
    team: null,
}


class _ReportsStore extends ListStore implements IReportsStore {
    items = [] as IReport[]
    editItem = {
        properties: {},
        teams: [] as IReportDetail['teams'],
        result: [] as IReportDetail['result'],
        stats: [] as IReportDetail['stats'],
    } as IReportDetail
    teams = [] as IPropertyItem[]
    constructor() {
        super();
        this.name = '__reports__'
        makeObservable(this, {
            items: observable,
            editItem: observable,
            setItems: action,
        })
    }
    setItems = (items: IReport[]) => {
        this.items = items;
    }
    setEditItemFull = (item: IReportDetail) => {
        this.editItem = item
        this.setTeams()
    }
    setEditItem = <K extends keyof IReportDetail>(
        field: K,
        value: IReportDetail[K]) => {
        console.log(field, value);
        this.editItem[field] = value
    }
    setEditItemProperties = <K extends keyof IReportDetail['properties']>(
        field: K,
        value: IReportDetail['properties'][K]) => {
        this.editItem.properties[field] = value
    }
    setEditItemTeams = (index: number, value: number) => {
        console.log(index, value)
        this.editItem.teams[index] = value
        this.setTeams()
    }
    setTeams = () => {
        let arr: IPropertyItem[] = []
        let list = AppStore.getProperty('team').list

        this.editItem.teams.map(id => {
            list.map(item => {
                if(id === item.id){
                    arr.push({...item})
                }
            })
        })
        this.teams = arr;
    }
    addEditItemTeam = () => {
        this.editItem.teams.push(undefined)
    }
    removeEditItemTeam = (id: number) => {
        this.editItem.teams.splice(id, 1);
        this.setTeams()
    }
    setEditItemResultTeam = (index: number, teamId: number, id: number) => {
        this.editItem.result[index].teams[teamId].id = id
    }
    setEditItemResultTeamScore = (id: number, teamId: number, score: number) => {
        this.editItem.result[id].teams[teamId].score = score
    }
    setEditItemResultSort = (id: number, sort: number) => {
        this.editItem.result[id].sort = sort;
    }
    removeEditeItemResult = (id: number) => {
        this.editItem.result.splice(id, 1);
    }
    addEditItemResult = () => {
        let sort = this.editItem.result[this.editItem.result.length - 1]?.sort
        this.editItem.result.push({
            sort: sort ? sort + 100 : 100,
            teams: [
                {
                    id: 0,
                    score: 0,
                },
                {
                    id: 0,
                    score: 0,
                }
            ]
        })
    }
    setEditItemStats = (id: number, value: any) => {

    }
    setEditItemPropertiesStats = <K extends keyof IReportDetail['stats'][0]>(
        id: number,
        field: K,
        value: IReportDetail['stats'][0][K]) => {
        let item = this.editItem.stats[id]
        item[field] = value
    }
    addEditItemStat = (customer: IReportCustomer) => {
        let stat = { ...TStat, customer: customer }
        this.editItem.stats.push(stat)
    }
    removeEditItemStat = (id: number) => {
        this.editItem.stats.splice(id, 1)
    }
    setWinner = (id: number) => {
        this.editItem.winner = id
    }
}

const ReportsStore = new _ReportsStore()

export default ReportsStore;