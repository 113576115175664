import { FC, useState } from "react";
import './styles.scss';
import { getCl, getClR } from "../../helper";
import Table from "../table/table";
import EmptyBox from "../emptyBox/emptyBox";

export interface ITable {
    thead: {
        title: any,
        width?: string,
        align?: 'center' | 'right',
        className?: string,
    }[],
    groups: {
        title: string,
        tbody: any[][],
    }[],
    className?: string
}

export const TableList: FC<ITable> = (props: ITable) => {
    const [headCellWidth, setHeadCellWidth] = useState<number[]>([]);
    // debugger
    return (
        <div className={`tableList ${getClR(props.className)}`}>
            <div className="tableList__head">
                <Table
                    thead={props.thead}
                    headCellWidth={headCellWidth}
                />
            </div>
            <div className="tableList__body">
                {props.groups.length ? props.groups.map((group, i) => {
                    return (
                        <div className="group" key={'group_' + i}>
                            <div className="group__title">{group.title}</div>
                            <div className="group__body">
                                <Table
                                    thead={props.thead}
                                    disableHead={true}
                                    tbody={group.tbody}
                                    setHeadCellWidth={setHeadCellWidth}
                                />
                            </div>
                        </div>
                    )
                }) : <div className="group"><EmptyBox /></div>}
            </div>
        </div>
    )
}

export const TableList_LoadMask: FC = () => {
    const count = 10;
    return (
        <div className="tableList__loadMask">
            <div className="tableList__head"></div>
            {Array.from({ length: count }).map((_, i) =>
                <div className="group" key={'groupMask_' + i}>
                    <div className="group__title"></div>
                    <div className="group__body">
                    </div>
                </div>
            )}
        </div>
    )
}


export default TableList;