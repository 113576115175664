import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page, { PageHead } from "../ui/page/page";
import { Table_LoadMask } from "../ui/table/table";
import Tile from "../components/tile/tile";
import Button from "../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormRow } from "../ui/form/form";
import Input from "../ui/input/input";
import Gallery, { IGalleryImage } from "../components/gallery/gallery";
import GallerySimple from "../components/gallery/gallerySimple";
import { setRequest } from "../API";
import NotifStore from "../store/_notifStore";
import { IReportCustomer } from "../store/reportsStore";
import { IPropertyItem } from "../store/store";
import { IPlace } from "../store/placesStore";
import { ITicketsItem } from "../store/ticketsStore";

interface Props {

}

let defaultProperty = {
    id: 0,
    title: 'Не выбрано'
}

const Notice_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false)

    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [image, setImage] = useState<IGalleryImage[]>([])
    const [users, setUsers] = useState<IPropertyItem[]>([defaultProperty])
    const [places, setPlaces] = useState<IPropertyItem[]>([defaultProperty])
    const [tickets, setTickets] = useState<IPropertyItem[]>([defaultProperty])
    const [userId, setUserId] = useState<number | null>(0)
    const [placeId, setPlaceId] = useState<number | null>(0)
    const [ticketId, setTicketId] = useState<number | null>(0)
    const [cityEnable, setCityEnable] = useState<boolean>(false)
    const [usersLoading, setUsersLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoaded(true)
        getPlaces()
        getTickets()
    }, [])


    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: 'POST',
            url: '/notice/',
            data: {
                title,
                description,
                userId,
                placeId,
                ticketId,
                city: cityEnable,
                image: image[0]?.image
            },
            success: payload => {
                NotifStore.setSuccess("Успех", 'отправлено')
                setLoaded(true)
            },
            error: err => {
                NotifStore.setWarning("Не успех", 'не отправлено')
                setLoaded(true)
            }
        })
    }


    const getUsers = (str: string) => {
        if (!str) return
        setUsersLoading(true)
        setRequest({
            type: 'GET',
            url: `/customers/search/?query=${str}`,
            success: payload => {
                console.log(payload)
                let customerArr = payload.data.map((customer: IReportCustomer) => {
                    return {
                        id: customer.id,
                        title: `${customer.surname} ${customer.name} ${customer.phone}`,
                        customer: customer
                    }
                })
                // customerArr.unshift(defaultProperty)
                setUsers(customerArr)
                setUsersLoading(false)
                // setLoading(false)
            },
            error: (err) => {
                setUsersLoading(false)
                // setLoading(false)
            }
        })
    }

    const getPlaces = () => {
        setRequest({
            type: 'GET',
            url: `/places/`,
            success: payload => {
                let arr = payload.data.map((place: IPlace) => {
                    return {
                        id: place.id,
                        title: place.title
                    }
                })
                arr.unshift(defaultProperty)
                setPlaces(arr)
                // setLoading(false)
            },
            error: (err) => {
                // setLoading(false)
            }
        })
    }

    const getTickets = () => {
        setRequest({
            type: 'GET',
            url: '/tickets/',
            success: (payload) => {
                let arr = payload.data.map((ticket: ITicketsItem) => {
                    return {
                        id: ticket.id,
                        title: `#${ticket.id} ${ticket.place_title}`
                    }
                })
                arr.unshift(defaultProperty)
                // setLoaded(true)
                setTickets(arr)
            },
            error: (err) => {
                // setLoaded(true)
                // setData([])
            }
        })
    }

    return (
        <Page>
            <PageHead
                title={"Рассылка"}
            // actions={<><Button type="primary" icon="plus" onClick={() => { navigate('/promo/add') }} >Добавить</Button></>}
            />
            <Tile
                loaded={true}
            >
                <Form asModule={false}>
                    <FormBody>
                        <FormRow>
                            <FormCol>
                                <Input
                                    type="text"
                                    label="Заголовок"
                                    value={title}
                                    onChange={setTitle}
                                // error={PromoStore.editItemError.amount}
                                />
                                <Input
                                    type="textarea"
                                    label="Текст"
                                    value={description}
                                    onChange={setDescription}
                                // error={PromoStore.editItemError.amount}
                                />

                                <Input
                                    type="select"
                                    // type="multiselect"
                                    placeholder={'Начните вводить'}
                                    label={"Выбрать игрока"}
                                    value={userId}
                                    options={users}
                                    loading={usersLoading}
                                    onInput={val => {
                                        getUsers(val)
                                    }}
                                    onChange={(id) => {
                                        setUserId(id)
                                    }}
                                />

                                <Input
                                    type="select"
                                    // type="multiselect"
                                    label={"Выбрать площадку"}
                                    value={placeId}
                                    options={places}
                                    onChange={(id) => {
                                        setPlaceId(id)
                                    }}
                                />
                                <Input
                                    type="select"
                                    // type="multiselect"
                                    label={"Выбрать билет"}
                                    value={ticketId}
                                    options={tickets}
                                    onChange={(id) => {
                                        setTicketId(id)
                                    }}
                                />
                                <Input
                                    type="checkbox"
                                    label={"Включить город"}
                                    value={cityEnable}
                                    onChange={(val) => {
                                        setCityEnable(val)
                                    }}
                                />
                                <Button
                                    type="primary"
                                    icon="check"
                                    onClick={onSubmit}
                                    loading={!loaded}
                                    disabled={!title}
                                >
                                    Отправить
                                </Button>
                            </FormCol>
                            <FormCol>
                                <GallerySimple
                                    items={image}
                                    max={1}
                                    big={true}
                                    setItems={setImage}
                                />
                            </FormCol>
                        </FormRow>
                    </FormBody>
                    {/* <FormActions>
                        <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Отправить</Button>
                    </FormActions> */}
                </Form>
            </Tile>
        </Page>
    )
})

export default Notice_Page