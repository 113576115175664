import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './css/styles.scss';
import Sidebar from './components/sidebar/sidebar';
import LoginPage from './pages/loginPage';
import IndexPage from './pages/indexPage';
import AppStore from './store/store';
import { observer } from 'mobx-react-lite';
import DashBoard_Page from './pages/dashboardPage';
import Header from './components/header/header';
import { checkToken, setRequest, setRequestList } from './API';
import Orders_Page from './pages/ordersPage';
import { getCl, getCookie, setCookie } from './helper';
import Tickets_List_Page from './pages/tickets/listPage';
import Tickets_Add_Page from './pages/tickets/addPage';
import Tickets_Edit_Page from './pages/tickets/editPage';
import Notif from './components/notif/notif';
import NotifStore from './store/_notifStore';
import Places_List_Page from './pages/places/listPage';
import Places_Add_Page from './pages/places/addPage';
import Places_Edit_Page from './pages/places/editPage';
import Themer from './components/themer';
import Reports_List_Page from './pages/reports/listPage';
import Reports_Add_Page from './pages/reports/addPage';
import Reports_Edit_Page from './pages/reports/editPage';
import Reports_Edit_Teams_Page from './pages/reports/edit/editPageTeams';
import Reports_Edit_Games_Page from './pages/reports/edit/editPageGames';
import Reports_Edit_Stats_Page from './pages/reports/edit/editPageStats';
import Promo_List_Page from './pages/promo/listPage';
import Promo_Add_Page from './pages/promo/addPage';
import Promo_Edit_Page from './pages/promo/editPage';
import Notice_Page from './pages/noticePage';
import Static_Page from './pages/staticPage';
import Players_Page from './pages/playersPage';

const App = observer(() => {
  const [inited, setInited] = useState<boolean>(false);

  return (
    <div id="__site_wrapper" className={`App ${getCl(!AppStore.isLogged, 'otlg')} ${getCl(inited, 'inited')}`}>
      <Auth_Worker setInited={setInited} />
      <Notif />
      <Themer />
      {!inited ? <></> :
        !AppStore.isLogged ?
          <main>
            <Routes>
              <Route path='/auth' element={<LoginPage />} />
            </Routes>
          </main>
          :
          <>
            <Header />
            <Sidebar items={[
              {
                link: '/dashboard',
                text: 'Финансы',
                icon: 'activity',
              },
              {
                link: '/tickets',
                text: 'Игры',
                icon: 'server',
              },
              {
                link: '/places',
                text: 'Площадки',
                icon: 'flag',
              },
              {
                link: '/orders',
                text: 'Заказы',
                icon: 'shopping-cart',
              },
              {
                link: '/reports',
                text: 'Отчеты',
                icon: 'file',
              },
              {
                link: '/promo',
                text: 'Промокоды',
                icon: "feather",
              },
              {
                link: '/notice',
                text: 'Рассылка',
                icon: "mail",
              },
              {
                link: '/static',
                text: 'Статика',
                icon: "cast",
              },
              {
                link: '/players',
                text: 'База игроков',
                icon: "users",
              },
            ]} />
            <main>
              <Routes>
                <Route path='/' element={<IndexPage />} />
                <Route path='/dashboard' element={<DashBoard_Page />} />
                <Route path='/places'>
                  <Route index element={<Places_List_Page />} />
                  <Route path='add' element={<Places_Add_Page />} />
                  <Route path=':id' element={<Places_Edit_Page />} />
                </Route>
                <Route path='/tickets'>
                  <Route index element={<Tickets_List_Page />} />
                  <Route path="add" element={<Tickets_Add_Page />} />
                  <Route path=":id" element={<Tickets_Edit_Page />} />
                </Route>
                <Route path='/promo'>
                  <Route index element={<Promo_List_Page />} />
                  <Route path="add" element={<Promo_Add_Page />} />
                  <Route path=":id" element={<Promo_Edit_Page />} />
                </Route>
                <Route path='/orders' element={<Orders_Page />} />
                <Route path='/notice' element={<Notice_Page />} />
                <Route path='/reports'>
                  <Route index element={<Reports_List_Page />} />
                  <Route path="add" element={<Reports_Add_Page />} />
                  <Route path=":id" element={<Reports_Edit_Page />}>
                    <Route path="teams" element={<Reports_Edit_Teams_Page />} />
                    <Route path="games" element={<Reports_Edit_Games_Page />} />
                    <Route path="stats" element={<Reports_Edit_Stats_Page />} />
                  </Route>
                </Route>
                <Route path='/static' element={<Static_Page />} />
                <Route path='/players' element={<Players_Page />} />
              </Routes>
            </main>
          </>
      }
    </div>
  );
})

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}


interface AuthWorkerProps {
  setInited: (value: boolean) => void
}

const Auth_Worker: FC<AuthWorkerProps> = (props: AuthWorkerProps) => {
  const navigate = useNavigate();
  const [tokenInited, setTokenInited] = useState<boolean>(false)
  const location = useLocation();

  useEffect(() => {
    console.log('TEST RECONN', tokenInited)
    if (!tokenInited) {
      checkToken(() => {
        setTokenInited(true)
      }, () => {
        setRequestList({
          requests: [
            {
              type: "GET",
              url: '/properties/',
              success: (payload) => {
                AppStore.setProperties(payload.data)
              },
            },
            {
              type: "GET",
              url: '/profile/',
              success: (payload) => {
                AppStore.setUser(payload.data)
              },
            },
          ],
          successList: () => {
            props.setInited(true)
          },
          errorList: () => {
            props.setInited(false)
          }
        })
      }, () => {
        props.setInited(true)
      });
    }
    if (tokenInited) {
      if (!AppStore.isLogged) {
        navigate('/auth')
      }
      if (AppStore.isLogged) {
        if (location.pathname.includes('auth') || location.pathname === '/') {
          setTokenInited(false)
          props.setInited(false)
          navigate('/dashboard')
        }
      }
    }
  }, [AppStore.isLogged, tokenInited])

  return (
    <></>
  )
}

export default AppWrapper;
