import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page, { PageHead } from "../ui/page/page";
import { Table_LoadMask } from "../ui/table/table";
import Tile from "../components/tile/tile";
import Button from "../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormRow } from "../ui/form/form";
import Input from "../ui/input/input";
import StaticStore from "../store/_staticStore";
import { setRequest } from "../API";
import NotifStore from "../store/_notifStore";

interface Props {

}


const Static_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setLoaded(false)
        setRequest({
            url: '/statics/read/',
            type: "GET",
            success: payload => {
                StaticStore.setInfo(payload.data)
                setLoaded(true)
            },
        })
    }

    const submit = () => {
        setLoading(true)
        const data = {
            id: StaticStore.id,
            subscriptionPrice: StaticStore.subscriptionPrice,
            singleGamePrice: StaticStore.singleGamePrice,
            singleGameOldPrice: StaticStore.singleGameOldPrice,
            bannerText: StaticStore.bannerText,
            promoText: StaticStore.promoText,
        }
        setRequest({
            url: '/statics/update/',
            type: "POST",
            data,
            success: payload => {
                NotifStore.setSuccess('Успех', 'Данные обновлены')
                setLoading(false)
                load();
            },
        })
    }

    return (
        <Page>
            <PageHead
                title={"Игры по абонементам"}
            // actions={<><Button type="primary" icon="plus" onClick={() => { navigate('/promo/add') }} >Добавить</Button></>}
            />
            <Tile
                loaded={loaded}
            >
                <Form asModule={false}>
                    <FormBody>
                        <FormRow>
                            <FormCol>
                                {/* <Input
                                    type="number"
                                    label="id"
                                    value={StaticStore.id}
                                    onChange={v => StaticStore.setId(v)}
                                /> */}
                                <Input
                                    type="number"
                                    label="Стоимость по абонементу от на странице абонементов"
                                    value={StaticStore.subscriptionPrice}
                                    onChange={v => StaticStore.setSubscriptionPrice(v)}
                                />
                                <Input
                                    type="number"
                                    label="Разовая игра из расписания"
                                    value={StaticStore.singleGamePrice}
                                    onChange={v => StaticStore.setSingleGamePrice(v)}
                                />
                                <Input
                                    type="number"
                                    label="Старая цена одной игры"
                                    value={StaticStore.singleGameOldPrice}
                                    onChange={v => StaticStore.setSingleGameOldPrice(v)}
                                />
                                <Input
                                    type="text"
                                    label="Текст баннера"
                                    value={StaticStore.bannerText}
                                    onChange={v => StaticStore.setBannerText(v)}
                                />
                                <Input
                                    type="text"
                                    label="Текст промо"
                                    value={StaticStore.promoText}
                                    onChange={v => StaticStore.setPromoText(v)}
                                />
                                <Button
                                    type="primary"
                                    icon="check"
                                    onClick={submit}
                                    loading={loading}
                                // disabled={!title}
                                >
                                    Отправить
                                </Button>
                            </FormCol>
                        </FormRow>
                    </FormBody>
                    {/* <FormActions>
                        <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Отправить</Button>
                    </FormActions> */}
                </Form>
            </Tile>
        </Page>
    )
})

export default Static_Page