import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import Button from "../../ui/button/button";
import Tile from "../../components/tile/tile";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import PlacesStore, { IPlace } from "../../store/placesStore";
import Table, { Table_LoadMask } from "../../ui/table/table";
import AppStore from "../../store/store";
import Status from "../../components/status/status";
import Paginator from "../../components/paginator/paginator";

interface Props {

}
const Places_List_Page: FC<Props> = observer((props: Props) => {
   
    const [loaded, setLoaded] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        PlacesStore.setPaginationLimit(20)
    }, [])

    useEffect(() => {
        // setData([])
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: `/places/?${PlacesStore.getPaginationUrl()}`,
            success: (payload) => {
                setLoaded(true)
                PlacesStore.setItems(payload.data)
                PlacesStore.setPagination(payload.pagination)
            },
            error: (err) => {
                PlacesStore.setItems([])
                setLoaded(true)
            }
        })
    }, [PlacesStore.pagination.page])

    return (
        <Page>
            <PageHead title={"Площадки"} actions={
                <>
                    <Button
                        type="primary"
                        onClick={() => { navigate('/places/add') }}
                        icon="plus"
                    >
                        Добавить
                    </Button>
                </>
            } />
            <Tile
                loaded={loaded}
                loadMask={<Table_LoadMask />}
            >
                <Table thead={[
                    {
                        title: '#',
                        width: '8%',
                    },
                    {
                        title: 'Название',
                        width: '40%',
                    },
                    {
                        width: '20%',
                        title: 'Адрес',
                    },
                    {
                        width: '12%',
                        title: 'Метро',
                    },
                    {
                        width: '10%',
                        title: 'Тип',
                    },
                    {
                        width: '15%',
                        title: 'Статус',
                    },
                    {
                        width: '40px',
                        title: '',
                    },
                ]}
                    tbody={PlacesStore.items.map((place: IPlace) => [
                        place.id,
                        <NavLink to={`/places/${place.id}`}>{place.title}</NavLink>,
                        place.address,
                        AppStore.getPropertyByNameId('metro', place.properties.metro)?.title,
                        AppStore.getPropertyByNameId('area', place.properties.area)?.title,
                        <Status {...place.status} />,
                        <div className="actions">
                            {/* <Button type="primary" icon="edit" color={'yellow'} /> */}
                            <Button type="primary" icon="trash" color={'red'} disabled={true} />
                        </div>
                    ])}
                />
            </Tile>
            <Paginator {...PlacesStore.pagination} goToPage={p => PlacesStore.goToPage(p)} />
        </Page>
    )
})

export default Places_List_Page