import { FC, ReactNode, useEffect, useState } from "react";
import './styles.scss';
import Widget from "../widget";
import Input from "../../../ui/input/input";
import Table from "../../../ui/table/table";
import { setRequest } from "../../../API";
import NotifStore from "../../../store/_notifStore";
import { IPropertyItem } from "../../../store/store";

interface IWidgetList {
    name: string,
    amount: string,
    comission: string,

}
interface IWidgetYear {
    year: number,
    list: IWidgetList[]
}

export interface IWidget {

}

const Widget_Turnover: FC<IWidget> = (props: IWidget) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<IWidgetYear[]>([])
    const [list, setList] = useState<IWidgetList[]>([])
    const [years, setYears] = useState<IPropertyItem[]>([])
    const [year, setYear] = useState<number>(0)

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        if (!data.length) return
        let years: IPropertyItem[] = [];
        let i = 0;
        data.map(year => {
            let _year: IPropertyItem = {
                id: i,
                title: year.year.toString(),
            }
            i++;
            years.push(_year)
        })
        setYears(years)
    }, [data])

    useEffect(() => {
        if (!years.length) return
        let y = years.filter(_year => _year.id === year)[0]
        let list = data.filter(year => year.year.toString() === y.title)[0]?.list;
        // console.log(list)
        // debugger
        setList(list)
    }, [years, year])

    const load = () => {
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: '/widgets/turnover/',
            success: payload => {
                setLoaded(true)
                setData(payload.data)
            },
            error: err => {
                NotifStore.setWarning('Ошибка загрузки виджета', 'Виджет #turnover')
            }
        })
    }


    return (

        <Widget
            className="_turnover"
            label="Оборот за месяц"
            loaded={loaded}
            actions={years &&
                <Input
                    type="select"
                    value={year}
                    options={years}
                    onChange={id => {
                        console.log(id)
                        setYear(id)
                    }}
                />
            }
        >
            <Table
                thead={[
                    {
                        title: 'Месяц',
                    },
                    {
                        title: 'Оборот',
                    },
                    {
                        title: 'Комиссия',
                    },
                ]}
                tbody={list.map(month => {
                    return [
                        month.name,
                        month.amount,
                        month.comission,
                    ]
                })}
            />
        </Widget>
    )
}

export default Widget_Turnover;