import { makeAutoObservable } from "mobx";
import { _IS_DEV } from "../API";

export interface IUser {
    id: number,
    name: string,
    surname: string,
    image: string,
    email: string,
    properties: {
        city: number | null
    }
}

export interface IPropertyItem {
    id: number,
    title: string,
    icon?: string,
    color?: string
}
interface IProperty {
    name: string,
    type: 'single' | 'multiple',
    list: IPropertyItem[]
}

interface IStore {
    isLogged: boolean,
    user: IUser,
    ui: {
        theme: string
        sidebar: {
            isActive: boolean
        }
    }
}

console.log('init store')

class Store implements IStore {
    isLogged = false
    ui = {
        theme: 'dark',
        sidebar: {
            isActive: false
        }
    }
    user = {
        id: 0,
        name: '',
        surname: '',
        image: '',
        email: '',
        properties: {
            city: null as number | null
        }
    }
    properties = {} as IProperty[]
    constructor() {
        makeAutoObservable(this)
        setTimeout(() => {
            if (_IS_DEV) {
                this.user.email = 'yb.apple.dev@gmail.com'
            }
        }, 0)
    }
    changeTheme = (type?: string) => {
        if (type) {
            this.ui.theme = type
        } else {
            this.ui.theme = this.ui.theme === 'dark' ? 'light' : 'dark'
        }
    }
    setLogged = (type: boolean) => {
        this.isLogged = type
    }
    getUserEmail = () => {
        return this.user.email
    }
    setUser = (user: IUser) => {
        this.user = user;
    }
    setUserEmail = (login: string) => {
        this.user.email = login
    }
    setSidebarActive = (type?: boolean) => {
        if(type === undefined){
            this.ui.sidebar.isActive = !this.ui.sidebar.isActive;
        } else {
            this.ui.sidebar.isActive = type;
        }
    }
    setProperties = (props: IProperty[]) => {
        this.properties = props;
    }
    getProperty = (name: string) => {
        // console.log(name)
        let prop = this.properties.filter(prop => prop.name === name)[0];
        return prop
    }
    getPropertyByNameId = (name: string, id: number | null) => {
        if (id === null) return
        let propType = this.getProperty(name)
        if (propType && id) {
            let propObject = propType.list.filter(prop => prop.id === id)[0]
            if (propObject) {
                return propObject
            }
        }
    }
}

const AppStore = new Store()

export default AppStore;