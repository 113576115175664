import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { setRequest } from "../../../API";
import ReportsStore from "../../../store/reportsStore";
import NotifStore from "../../../store/_notifStore";
import Page, { PageBody, PageHead } from "../../../ui/page/page";
import Form, { FormActions, FormBody, FormCol, FormHr, FormRow } from "../../../ui/form/form";
import Input from "../../../ui/input/input";
import Tile from "../../../components/tile/tile";
import Button from "../../../ui/button/button";
import AppStore from "../../../store/store";


interface Props {
}

const Reports_Edit_Teams_Page: FC<Props> = observer((props: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { cancel, loaded, onSubmit } = useOutletContext<{ cancel: () => void, loaded: boolean, onSubmit: any }>()

    return (
        <Form asModule={false}>
            <FormBody>
                {ReportsStore.editItem.teams.map((team, i) => {
                    return (
                        <FormRow align="center">
                            <FormCol>
                                <Input
                                    type="select"
                                    value={team}
                                    label={`Команда ${i + 1}`}
                                    options={AppStore.getProperty('team').list}
                                    onChange={id => ReportsStore.setEditItemTeams(i, id)}
                                    disableIconColor={true}
                                />
                            </FormCol>
                            <FormCol width={'min-content'}>
                                <Button type="primary" icon="trash" color="red" onClick={() => { ReportsStore.removeEditItemTeam(i) }}></Button>
                            </FormCol>
                        </FormRow>
                    )
                })}
                <FormHr />
            </FormBody>
            <FormActions>
                <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Применить</Button>
                <Button type="secondary" onClick={cancel}>Отменить</Button>
                <div style={{ flexGrow: 1 }} />
                <Button type="primary" icon="plus" color="purple" onClick={() => { ReportsStore.addEditItemTeam() }}>Добавить</Button>
            </FormActions>
        </Form>
    )
})

export default Reports_Edit_Teams_Page