import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import ListStore from "./_listStore";

export interface IPlayer {
    id: number,
    name: string,
    lastName: string,
    email: string,
    phone: string,
}

interface IStore {
    items: IPlayer[];
}

class _PlayersStore extends ListStore implements IStore {
    items: IPlayer[] = []
    constructor() {
        super();
        this.name = '__orders__'
        makeObservable(this, {
            items: observable,
            setItems: action,
        })
    }
    setItems = (items: IPlayer[]) => {
        this.items = items
    }
}

const PlayersStore = new _PlayersStore()

export default PlayersStore;