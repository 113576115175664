import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import Button from "../../ui/button/button";
import TableList, { TableList_LoadMask } from "../../ui/tablelist/tablelist";
import Tile from "../../components/tile/tile";
import { NavLink, useNavigate } from "react-router-dom";
import TicketsStore, { ITicketsItem } from "../../store/ticketsStore";
import Tickets_List from "../../components/_tickets/list";
import { observer } from "mobx-react-lite";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";
import Table, { Table_LoadMask } from "../../ui/table/table";
import PromoStore, { IPromo } from "../../store/promoStore";
import Status from "../../components/status/status";
import Paginator from "../../components/paginator/paginator";
import AppStore from "../../store/store";

interface Props {

}
const Promo_List_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loadingReport, setLoadingReport] = useState<number | null>(null)
    const [loadingRemove, setLoadingRemove] = useState<number | null>(null)

    useEffect(() => {
        setLoaded(false)
        load()
    }, [])


    const load = () => {
        setRequest({
            type: 'GET',
            url: `/promocodes/list/?${PromoStore.getPaginationUrl()}`,
            success: (payload) => {
                setLoaded(true)
                PromoStore.setItems(payload.data)
            },
            error: (err) => {
                setLoaded(true)
                // setData([])
            }
        })
    }


    const handleRemove = (id: number | null) => {
        setLoadingRemove(id)
        setRequest({
            type: 'POST',
            url: '/promocodes/delete/',
            data: { id },
            success: payload => {
                load()
                NotifStore.setSuccess('Промокод удален', 'Промокод #' + id)
                setLoadingRemove(null)
            },
            error: err => {
                NotifStore.setWarning('Ошибка при удалении промокода', 'Промокод #' + id)
                setLoadingRemove(null)
            },
        })
    }

    return (
        <Page>
            <PageHead
                title={"Промокоды"}
                actions={<><Button type="primary" icon="plus" onClick={() => { navigate('/promo/add') }} >Добавить</Button></>}
            />
            <Tile
                loaded={loaded}
                loadMask={<Table_LoadMask />}
            >
                <Table thead={[
                    {
                        title: '#',
                        width: '8%',
                    },
                    {
                        title: 'Название',
                        width: '40%',
                    },
                    {
                        width: '20%',
                        title: 'Тип',
                    },
                    {
                        width: '12%',
                        title: 'Сумма/Процент',
                    },
                    {
                        width: '15%',
                        title: 'Статус',
                    },
                    {
                        width: '40px',
                        title: '',
                    },
                ]}
                    tbody={PromoStore.items.map((promo: IPromo) => [
                        promo.id,
                        <NavLink to={`/promo/${promo.id}`}>{promo.title}</NavLink>,
                        AppStore.getPropertyByNameId('promocodeTypes', promo.type)?.title,
                        `${promo.amount} ${promo.currency}`,
                        // AppStore.getPropertyByNameId('area', place.properties.area)?.title,
                        <Status {...promo.status} />,
                        <div className="actions">
                            <Button type="primary" icon="trash" color="red" loading={promo.id === loadingRemove} onClick={() => { handleRemove(promo.id) }} />
                        </div>
                    ])}
                />
            </Tile>
            <Paginator {...PromoStore.pagination} goToPage={p => PromoStore.goToPage(p)} />
        </Page>
    )
})

export default Promo_List_Page