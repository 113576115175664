import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import InputText from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import TicketsStore, { ICustomer, ITicketsItem } from "../../store/ticketsStore";
import Input from "../../ui/input/input";
import AppStore from "../../store/store";
import PlacesStore, { IPlace } from "../../store/placesStore";
import { observer } from "mobx-react-lite";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";
import Gallery from "../../components/gallery/gallery";
import YandexMap from "../../components/map/yandexMap";
import ReportsStore from "../../store/reportsStore";
import Tabs from "../../ui/tabs/tabs";
import Tab from "../../ui/tabs/tab";


interface Props {

}

const Reports_Edit_Page: FC<Props> = observer((props: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [loaded, setLoaded] = useState<boolean>(false)
    const [inited, setInited] = useState<boolean>(false)

    useEffect(() => {
        console.log('init')
        load()
    }, [])

    const load = () => {
        setInited(false)
        setRequest({
            type: 'GET',
            url: `/reports/read/?id=${id}`,
            success: (payload) => {
                ReportsStore.setEditItemFull(payload.data)
                setLoaded(true)
                setInited(true)
            },
            error: (err) => {
                NotifStore.setWarning('Ошибка загрузки отчета', 'Отчет #' + id)
                setLoaded(false)
                // setPlaces([])
            }
        })
    }

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: 'POST',
            url: `/reports/update/`,
            data: ReportsStore.editItem,
            success: (payload) => {
                console.log(payload)
                NotifStore.setSuccess('Отчет успешно сохранен', 'Отчет #' + id)
                setLoaded(true)
                load()
                // setInited(true)
            },
            error: (err) => {
                NotifStore.setWarning('Ошибка сохранения отчета', 'Отчет #' + id)
                setLoaded(false)
                // setPlaces([])
            }
        })
    }

    const cancel = () => {
        navigate('/reports')
    }

    return (
        <Page>
            <PageHead
                title={"Изменить Отчет"}
                info={"#" + id}
            />
            <PageBody>
                <Tabs>
                    <Tab active={location.pathname.includes('/teams')} to="teams">Команды</Tab>
                    <Tab active={location.pathname.includes('/games')} to="games">Игры</Tab>
                    <Tab active={location.pathname.includes('/stats')} to="stats">Статистика</Tab>
                </Tabs>
                <Tile
                    loaded={inited}
                    loader={true}
                    // highlightOnUpdate={true}
                >
                    <Outlet context={{ loaded, cancel, onSubmit }} />
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Reports_Edit_Page