import { action, makeAutoObservable, makeObservable, observable } from "mobx";

export interface IPagination {
    total: number,
    page: number,
    limit: number,
}

interface IListStore {
    name: string
    pagination: {
        total: number,
        page: number,
        limit: number,
    };
}

class ListStore implements IListStore {
    name = ''
    pagination = {
        limit: 20,
        page: 1,
    } as IPagination
    constructor() {
        // makeAutoObservable(this)
        makeObservable(this, {
            name: observable,
            pagination: observable,
            getPaginationUrl: action,
            goToPage: action,
            setPaginationLimit: action,
        })
    }
    setPagination = (pagination: IPagination) => {
        this.pagination = pagination;
    }
    getPaginationUrl = () => {
        return `limit=${this.pagination.limit}&page=${this.pagination.page}`
    }
    goToPage = (page: number) => {
        console.log(page)
        this.pagination.page = page
    }
    setPaginationLimit = (limit: number) => {
        this.pagination.limit = limit
    }
}

export default ListStore