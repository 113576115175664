import { FC, ReactNode, useEffect, useRef, useState } from "react";
import './styles.scss';
import { getCl } from "../../helper";
import { observer } from "mobx-react-lite";
import { logOut } from "../../API";
import NotifStore, { INotif } from "../../store/_notifStore";

interface Props {
}

const Notif: FC<Props> = observer((props: Props) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // console.log("SETTED")
        if (ref.current && NotifStore.items.length) {
            let curItem = NotifStore.items.filter(item => item.id === NotifStore.lastDiedId)[0]
            if (curItem) {
                let offset = curItem.offset as number;
                ref.current.style.translate = `0 ${-offset}px`
            }
            // console.log('offset', offset)
        }
    }, [NotifStore.lastDiedId])

    useEffect(() => {
        // console.log(NotifStore.lastDiedId)
    }, [NotifStore.lastDiedId])

    return (
        <div className={`notifBox`}>
            <div className={`notifBox__list`} ref={ref}>
                {NotifStore.items.map((notif, index) => <NotifItem key={'notif__' + index} {...notif} />)}
            </div>
        </div>
    )
})

const NotifItem: FC<INotif> = (props: INotif) => {
    const [inited, setInited] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTimeout(() => {
            setInited(true)
        }, 10)
    }, [])

    useEffect(() => {
        if (ref.current) {
            // console.log(ref)
            NotifStore.setItemOffset(props.id as number, ref.current.offsetTop + ref.current.clientHeight + 8)
        }
    }, [ref])

    const classnames = [
        getCl(!!props.type, props.type),
        getCl(inited, 'inited'),
        getCl(props.died, 'died')
    ].join(' ')

    return (
        <div className={`notifBox__item ${classnames}`} data-id={props.id} ref={ref}>
            <div className="notifBox__item_title">{props.title}</div>
            {props.text &&
                <div className="notifBox__item_text">{props.text}</div>
            }
        </div>
    )
}

export default Notif;