import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import Button from "../../ui/button/button";
import Tile from "../../components/tile/tile";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import PlacesStore, { IPlace } from "../../store/placesStore";
import Table, { Table_LoadMask } from "../../ui/table/table";
import AppStore from "../../store/store";
import Status from "../../components/status/status";
import Paginator from "../../components/paginator/paginator";
import OrdersStore from "../../store/ordersStore";
import ReportsStore, { IReport } from "../../store/reportsStore";
import EmptyBox from "../../ui/emptyBox/emptyBox";
import NotifStore from "../../store/_notifStore";

interface Props {

}
const Reports_List_Page: FC<Props> = observer((props: Props) => {
   
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loadingRemove, setLoadingRemove] = useState<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        ReportsStore.setPaginationLimit(20)
        // setLoaded(true)
    }, [])

    useEffect(() => {
        setLoaded(false)
        load()
    }, [ReportsStore.pagination.page])

    const load = () => {
        setRequest({
            type: 'GET',
            url: `/reports/list/?${ReportsStore.getPaginationUrl()}`,
            success: (payload) => {
                setLoaded(true)
                ReportsStore.setItems(payload.data)
                ReportsStore.setPagination(payload.pagination)
            },
            error: (err) => {
                ReportsStore.setItems([]);
                setLoaded(true)
            }
        })
    }

    const handleRemove = (id: number) => {
        setLoadingRemove(id)
        setRequest({
            type: 'POST',
            url: '/reports/delete/',
            data: {id},
            success: payload => {
                load()
                NotifStore.setSuccess('Отчет удален', 'Отчет #' + id)
                setLoadingRemove(null)
            },
            error: err => {
                NotifStore.setWarning('Ошибка при удалении отчета', 'Отчет #' + id)
                setLoadingRemove(null)
            },
        })
    }

    return (
        <Page>
            <PageHead title={"Отчеты"} />
            <Tile
                loaded={loaded}
                loadMask={<Table_LoadMask />}
            >
                <Table thead={[
                    {
                        title: '#',
                        width: '8%',
                    },
                    {
                        width: '20%',
                        title: 'Дата',
                    },
                    {
                        title: 'Название',
                        width: '40%',
                    },
                    {
                        width: '12%',
                        title: 'Статус',
                    },
                    {
                        width: '12%',
                        title: 'Сезон',
                    },
                    {
                        width: '10%',
                        title: '',
                    },
                ]}
                    tbody={ReportsStore.items.map((report: IReport) => [
                        report.id,
                        report.date,
                        <NavLink to={`/reports/${report.id}/teams`}>{report.place_title}</NavLink>,
                        <Status {...report.status} />,
                        AppStore.getPropertyByNameId('season',report.properties.season)?.title,
                        <div className="actions">
                            <Button to={`/reports/${report.id}/games`} type="primary" icon="list" />
                            <Button to={`/reports/${report.id}/stats`} type="primary" icon="activity" />
                            <Button type="primary" icon="trash" color="red" loading={report.id === loadingRemove} onClick={() => {handleRemove(report.id)}}/>
                        </div>
                    ])}
                    empty={!ReportsStore.items.length}
                />
            </Tile>
            <Paginator {...ReportsStore.pagination} goToPage={p => ReportsStore.goToPage(p)} />
        </Page>
    )
})

export default Reports_List_Page