import { ChangeEvent, FC, useState } from "react";
import Page, { PageBody, PageHead, PageInDev } from "../ui/page/page";
import API, { setRequest } from "../API";
import InputText from "../ui/input/input";
import Button from "../ui/button/button";
import Form, { FormActions, FormBody, FormHeader } from "../ui/form/form";
import Widgets from "../components/widget/widgets";
import Widget from "../components/widget/widget";
import InputSelect from "../ui/input/inputSelect";
import Input from "../ui/input/input";
import Table from "../ui/table/table";
import Widget_Turnover from "../components/widget/turnover/widget";

interface Props {

}

const DashBoard_Page: FC<Props> = (props: Props) => {
    const [users, setUsers] = useState('default');

    const getUsers = () => {
        setRequest({
            type: 'GET',
            url: '/users/read/',
            success: (payload) => {
                setUsers(payload.jwt)
            },
            error: (err) => {
                // console.log
                setUsers('failed')
            }
        })
    }
    return (
        <Page className="dashboard">
            <PageHead title={"Финансы"} />
            <PageBody>
                <Widgets>
                    <Widget_Turnover />
                </Widgets>
                {/* <PageInDev /> */}
            </PageBody>
        </Page >
    )
}

export default DashBoard_Page