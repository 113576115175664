import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import InputText from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { useNavigate, useParams } from "react-router-dom";
import TicketsStore, { ICustomer, ITicketsItem } from "../../store/ticketsStore";
import Input from "../../ui/input/input";
import AppStore, { IPropertyItem } from "../../store/store";
import { IPlace } from "../../store/placesStore";
import { observer } from "mobx-react-lite";
import Image from "../../ui/image/image";
import Table from "../../ui/table/table";
import Modal from "../../ui/modal/modal";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";
import PromoStore from "../../store/promoStore";
import { IReportCustomer } from "../../store/reportsStore";

interface Props {

}

let defaultProperty = {
    id: 0,
    title: 'Не выбрано'
}

const Promo_Edit_Page: FC<Props> = observer((props: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState<boolean>(false)
    const [inited, setInited] = useState<boolean>(false)
    const [modalActive, setModalActive] = useState<boolean>(false)
    const [users, setUsers] = useState<IPropertyItem[]>([defaultProperty])
    const [places, setPlaces] = useState<IPropertyItem[]>([defaultProperty])
    const [usersLoading, setUsersLoading] = useState<boolean>(false)

    useEffect(() => {
        PromoStore.setEditItemError()
        load()
        getPlaces()
    }, [])

    const load = () => {
        setRequest({
            type: 'GET',
            url: `/promocodes/read/?id=${id}`,
            success: (payload) => {
                PromoStore.setEditItemFull(payload.data)
                setLoaded(true)
                setInited(true)
            },
            error: (err) => {
                NotifStore.setWarning('Ошибка загрузки промокода', 'Промокод #' + id)
                setLoaded(false)
                // setPlaces([])
            }
        })
    }

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: "POST",
            url: '/promocodes/update/',
            data: PromoStore.editItem,
            success: (payload) => {
                setLoaded(true)
                // load()
                PromoStore.setEditItemError()
                NotifStore.setSuccess('Промокод успешно изменен', 'Промокод #' + id)
                console.log(payload)
                navigate('/promo/')
            },
            error: payload => {
                if (payload.errors) {
                    PromoStore.setEditItemError(payload.errors)
                }
                setLoaded(true)
                NotifStore.setWarning('Ошибка изменения промокода', 'Промокод #' + id)
            }
        })
    }

    const getUsers = (str: string) => {
        if(!str) return
        setUsersLoading(true)
        setRequest({
            type: 'GET',
            url: `/customers/search/?query=${str}`,
            success: payload => {
                console.log(payload)
                let customerArr = payload.data.map((customer: IReportCustomer) => {
                    return {
                        id: customer.id,
                        title: `${customer.surname} ${customer.name} ${customer.phone}`,
                        customer: customer
                    }
                })
                customerArr.unshift(defaultProperty)
                setUsers(customerArr)
                setUsersLoading(false)
                // setLoading(false)
            },
            error: (err) => {
                setUsersLoading(false)
                // setLoading(false)
            }
        })
    }

    const getPlaces = () => {
        setRequest({
            type: 'GET',
            url: `/places/`,
            success: payload => {
                let arr = payload.data.map((place: IPlace) => {
                    return {
                        id: place.id,
                        title: place.title
                    }
                })
                arr.unshift(defaultProperty)
                setPlaces(arr)
                // setLoading(false)
            },
            error: (err) => {
                // setLoading(false)
            }
        })
    }
    // if (!id) return;

    // return <></>

    return (
        <Page>
            <PageHead
                title={"Изменить промокод"}
            />
            <PageBody>
                <Tile
                    className="ticketsAdd"
                    loaded={inited}
                    loader={true}
                >
                    <Form asModule={false}>
                        <FormBody>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label="Название"
                                        value={PromoStore.editItem.title}
                                        onChange={val => PromoStore.setEditItem('title', val)}
                                        error={PromoStore.editItemError.title}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label="Сумма/процент"
                                        value={PromoStore.editItem.amount}
                                        onChange={val => PromoStore.setEditItem('amount', val)}
                                        error={PromoStore.editItemError.amount}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Статус"}
                                        value={PromoStore.editItem.status}
                                        options={AppStore.getProperty('status').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('status', id)}
                                        error={TicketsStore.editItemError.status}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Тип"}
                                        value={PromoStore.editItem.type}
                                        options={AppStore.getProperty('promocodeTypes').list}
                                        onChange={(id) => PromoStore.setEditItem('type', id)}
                                        error={PromoStore.editItemError.type}
                                    // error={PromoStore.editItemError.sport}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        // type="multiselect"
                                        label={"Выбрать игрока"}
                                        value={PromoStore.editItem.userId}
                                        options={users}
                                        loading={usersLoading}
                                        onInput={val => {
                                            getUsers(val)
                                        }}
                                        onChange={(id) => {

                                            PromoStore.setEditItem('userId', id)
                                        }}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        // type="multiselect"
                                        label={"Выбрать площадку"}
                                        value={PromoStore.editItem.eventId}
                                        options={places}
                                        onChange={(id) => {
                                            PromoStore.setEditItem('eventId', id)
                                        }}
                                    />
                                </FormCol>
                            </FormRow>
                        </FormBody>
                        <FormActions>
                            <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Применить</Button>
                            <Button type="secondary" onClick={() => navigate('/promo')}>Отменить</Button>
                        </FormActions>
                    </Form>
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Promo_Edit_Page