import { makeAutoObservable } from "mobx";

export interface INotif {
    id?: number,
    title: string,
    text?: string,
    died?: boolean,
    offset?: number,
    type: 'success' | 'warning'
}

interface IStore {
    items: INotif[],
    lastDiedId: number
    delay: number,
    needUpdate: number
}

class _NotifStore implements IStore {
    items = [] as INotif[];
    lastDiedId = 0
    delay = 5000;
    needUpdate = 0;

    constructor() {
        makeAutoObservable(this)
    }
    addItem = (item: INotif) => {
        item.id = Math.random() * 1000
        this.items.push(item)

        this.setItem(item.id);
    }
    setItem = (id: number) => {
        let item = this.items.filter(el => el.id === id)[0];
        setTimeout(() => {
            if (item.id) {
                this.lastDiedId = item.id
                item.died = true
            }
        }, this.delay)
    }
    setItemOffset = (id: number, offset: number) => {
        let item = this.items.filter(el => el.id === id)[0];
        if (item) {
            item.offset = offset
            console.log(offset)
            this.setUpdate()
        }
    }
    setUpdate = () => {
        this.needUpdate = Math.random() * 1000
    }
    setSuccess = (title: string, text?: string) => {
        this.addItem({
            title,
            text,
            type: 'success'
        })
    }
    setWarning = (title: string, text?: string) => {
        this.addItem({
            title,
            text,
            type: 'warning'
        })
    }
}

const NotifStore = new _NotifStore()

export default NotifStore;