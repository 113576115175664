import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import Button from "../../ui/button/button";
import TableList, { TableList_LoadMask } from "../../ui/tablelist/tablelist";
import Tile from "../../components/tile/tile";
import { NavLink, useNavigate } from "react-router-dom";
import TicketsStore, { ITicketsItem } from "../../store/ticketsStore";
import Tickets_List from "../../components/_tickets/list";
import { observer } from "mobx-react-lite";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";

interface Props {

}
const Tickets_List_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loadingReport, setLoadingReport] = useState<number | null>(null)
    const [loadingRemove, setLoadingRemove] = useState<number | null>(null)

    useEffect(() => {
        setLoaded(false)
        load()
    }, [])

    const load = () => {
        setRequest({
            type: 'GET',
            url: '/tickets/?view=group',
            success: (payload) => {
                setLoaded(true)
                TicketsStore.setItems(payload.data)
            },
            error: (err) => {
                setLoaded(true)
                // setData([])
            }
        })
    }

    const handleReport = (id: number) => {
        setLoadingReport(id)
        setRequest({
            type: "POST",
            url: '/reports/add/',
            data: { id },
            success: payload => {
                NotifStore.setSuccess('Отчет добавлен', 'Билет #' + id)
                setLoadingReport(null)
                load();
            },
            error: err => {
                NotifStore.setWarning('Отчет уже существует', 'Билет #' + id)
                setLoadingReport(null)
            }
        })
    }

    const removeTicket = (id: number) => {
        setLoadingRemove(id)
        setRequest({
            type: "POST",
            url: '/tickets/',
            data: { id, delete: true },
            success: payload => {
                NotifStore.setSuccess('Билет удален', 'Билет #' + id)
                setLoadingRemove(null)
                load();
            },
            error: err => {
                NotifStore.setWarning('Ошибка при удалении билета', 'Билет #' + id)
                setLoadingRemove(null)
            }
        })
    }

    return (
        <Page>
            <PageHead
                title={"Игры"}
                actions={<><Button type="primary" icon="plus" onClick={() => { navigate('/tickets/add') }} >Добавить</Button></>}
            />
            <PageBody>
                <Tile
                    className="ticketsList"
                    loaded={loaded}
                    loadMask={<TableList_LoadMask />}
                >
                    <TableList
                        thead={[
                            {
                                title: '#',
                                width: '8%',
                            },
                            {
                                title: 'Название',
                                width: '42%',
                            },
                            {
                                title: 'Время',
                                width: '8%',
                            },
                            {
                                title: 'Цена',
                                width: '8%',
                            },
                            {
                                title: 'Мест',
                                width: '8%',
                            },
                            {
                                title: 'Продано',
                                width: '8%',
                            },
                            {
                                title: 'Бронь',
                                width: '8%',
                            },
                            {
                                title: '',
                                width: '80px',
                            },
                        ]}
                        groups={TicketsStore.items.map(item => {
                            let tbody = item.list.map(el => [
                                el.id,
                                <NavLink to={`/tickets/${el.id}`} >{el.place_title}</NavLink>,
                                getFormattedDate(el.date_start * 1000, 'hh:mm'),
                                el.price,
                                `${el.count} ${el.correction !== 0 ? (el.correction > 0 ? "-" : "+") + " " + Math.abs(el.correction) : ""} `,
                                el.sold,
                                el.booked,
                                <div className="actions">
                                    {/* <Button type="primary" icon="edit" color={'yellow'} /> */}
                                    <Button type="primary" icon="award" loading={el.id === loadingReport} onClick={() => { handleReport(el.id) }} />
                                    <Button disabled={!el.is_deleted} type="primary" icon="trash" color={'red'} loading={el.id === loadingRemove} onClick={() => { removeTicket(el.id) }} />
                                </div>
                            ])
                            let group = {
                                title: getFormattedDate(item.timestamp * 1000, 'dd MW yyyy'),
                                tbody: tbody
                            }
                            return group
                        })}
                    />
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Tickets_List_Page