import { makeAutoObservable } from "mobx";



interface IStore {
    id: number | undefined
    subscriptionPrice: number
    singleGamePrice: number
    singleGameOldPrice: number | undefined
    bannerText: string
    promoText: string
}

class _StaticStore implements IStore {
    id: number | undefined = undefined
    subscriptionPrice = 0
    singleGamePrice = 0
    singleGameOldPrice: number | undefined = undefined
    bannerText = ""
    promoText = ""
    constructor() {
        makeAutoObservable(this)
    }
    setId = (v: number | undefined) => {
        this.id = v;
    }
    setSubscriptionPrice = (v: number) => {
        this.subscriptionPrice = v
    }
    setSingleGamePrice = (v: number) => {
        this.singleGamePrice = v
    }
    setSingleGameOldPrice = (v: number | undefined) => {
        this.singleGameOldPrice = v
    }
    setBannerText = (v: string) => {
        this.bannerText = v;
    }
    setPromoText = (v: string) => {
        this.promoText = v;
    }
    setInfo = (v: IStore) => {
        this.id = v.id
        this.subscriptionPrice = v.subscriptionPrice
        this.singleGamePrice = v.singleGamePrice
        this.singleGameOldPrice = v.singleGameOldPrice
        this.bannerText = v.bannerText
        this.promoText = v.promoText
    }
}

const StaticStore = new _StaticStore()

export default StaticStore;