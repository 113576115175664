import { FC, ReactNode, useEffect, useState } from "react";
import './styles.scss';
import { getCl, getClR } from "../../helper";

export interface IWidget {
    children: ReactNode,
    loaded?: boolean,
    label: string,
    actions?: ReactNode,
    className?: string
}

const Widget: FC<IWidget> = (props: IWidget) => {

    const classnames = [
        getCl(props.loaded, 'loaded'),
        getClR(props.className),
    ].join(' ')

    return (
        <div className={`widget ${classnames}`}>
            <div className={`widget__inner`}>
                <div className={`widget__header`}>
                    <div className={`widget__label`}>
                        {props.label}
                    </div>
                    {props.actions &&
                        <div className={`widget__actions`}>
                            {props.actions}
                        </div>
                    }
                </div>
                <div className={`widget__body`}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Widget;