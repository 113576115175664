import { FC, ReactNode, useEffect, useState } from "react";
import './styles.scss';
import { getCl } from "../../helper";

export interface Props {
    children: ReactNode,
}

const Widgets: FC<Props> = (props: Props) => {
    return (
        <div className={`widgets`}>
            {props.children}
        </div>
    )
}

export default Widgets;